import React, { Component, Fragment } from 'react'
import { Navbar,Nav,NavDropdown, Container } from 'react-bootstrap'
import { BrowserRouter as Router, Switch, Route, Link, NavLink} from "react-router-dom";
import blackLogo from '../../asset/image/logo_black.png';
import whiteLogo from '../../asset/image/logo_white.png';
import '../../asset/css/bootstrap.min.css';
import '../../asset/css/custom.css';

class TopNavigation extends Component {
  
  constructor(props){
    super(props);
    this.state={
      navVariant:"dark",
      navBarTitle:"navTitle",
      navBarBack:"navBackground",      
      navBarItem:"navItem",
      navBarLogo:[whiteLogo],
      colorNav:"navTopMenuColor",
      pageTitle:props.title
    }
  }

  onScroll=()=>{
    if(window.scrollY>100){      
      this.setState({navBarTitle:'navTitleScroll',navBarLogo:[blackLogo],navBarBack:'navBackgroundScroll',navBarItem:'navItemScroll'})
    }else if(window.scrollY<100){      
      this.setState({navBarTitle:'navTitle',navBarLogo:[whiteLogo],navBarBack:'navBackground',navBarItem:'navItem'})
    }
  }

  componentDidMount(){
    window.addEventListener('scroll',this.onScroll)
  }
  
  render() {

    let activeStyle = {
      color:'#ffd900',
    };

    return (
      <Fragment>
      <title>{this.state.pageTitle}</title>
<Navbar className={this.state.navBarBack} collapseOnSelect fixed="top" expand="lg" variant={this.state.navVariant}>
<Container> 
  <Navbar.Brand className={this.state.navBarTitle} href="#home"><Link to="/"><img src={this.state.navBarLogo} /></Link></Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="me-auto">
    </Nav>
    <Nav>
    <Nav.Link><NavLink exact style={({ isActive }) => isActive ? activeStyle : undefined} className={this.state.navBarItem} to="/">HOME</NavLink></Nav.Link>
    <Nav.Link><NavLink exact style={({ isActive }) => isActive ? activeStyle : undefined}  className={this.state.navBarItem} to="/about">ABOUT</NavLink></Nav.Link>
    <Nav.Link><NavLink exact style={({ isActive }) => isActive ? activeStyle : undefined}  className={this.state.navBarItem} to="/service">SERVICE</NavLink></Nav.Link>
    <Nav.Link><NavLink exact style={({ isActive }) => isActive ? activeStyle : undefined}  className={this.state.navBarItem} to="/course">COURSES</NavLink></Nav.Link>
    <Nav.Link><NavLink exact style={({ isActive }) => isActive ? activeStyle : undefined}  className={this.state.navBarItem} to="/porfolio">PORTFOLIO</NavLink></Nav.Link>
    <Nav.Link><NavLink exact style={({ isActive }) => isActive ? activeStyle : undefined}  className={this.state.navBarItem} to="/contact">CONTACT US</NavLink></Nav.Link>
   </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>
      
</Fragment>

      
    )
  }
}

export default TopNavigation
