import React, { Component, Fragment } from 'react'
import {BrowserRouter as Router,Routes, Route, Link} from "react-router-dom";
import AllCoursesPage from '../pages/AllCoursesPage'
import AboutPage from '../pages/AboutPage';
import AllServicePage from '../pages/AllServicePage';
import ContactPage from '../pages/ContactPage';
import HomePage from '../pages/HomePage';
import PortfolioPage from '../pages/PortfolioPage';
import RefundPage from '../pages/RefundPage';
import TremsPage from '../pages/TremsPage';
import PrivacyPage from '../pages/PrivacyPage';
import ProjectDetailPage from '../pages/ProjectDetailPage';
import CourseDetailsPage from '../pages/CourseDetailsPage';

class AppRouter extends Component {
  render() {
    return (
        <Fragment>
          <Routes>
                  
                  <Route path="/" element={<HomePage/>} />
                  <Route path="/service" element={<AllServicePage/>}  />
                  <Route path="/course" element={<AllCoursesPage/>}  />
                  <Route path="/porfolio" element={<PortfolioPage/>}  />
                  <Route path="/about" element={<AboutPage/>}  />
                  <Route path="/contact" element={<ContactPage/>} /> 
                  <Route path="/refund" element={<RefundPage/>} /> 
                  <Route path="/trems" element={<TremsPage/>} />
                  <Route path="/privacy" element={<PrivacyPage/>} />
                  <Route path="/projectdetails" element={<ProjectDetailPage/>} />
                  <Route path="/coursedetails" element={<CourseDetailsPage/>} />
            </Routes>      
              
                  
        </Fragment>
    )
  }
}

export default AppRouter