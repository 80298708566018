import React, { Component, Fragment } from 'react'
import { Card, Col, Container, Row, Button } from 'react-bootstrap'
import page1IMG from '../../asset/image/page1.png';
import page2IMG from '../../asset/image/page2.png';
import page3IMG from '../../asset/image/page3.png';
import { Link } from 'react-router-dom'

class RecentProject extends Component {
  render() {
    return (
      <Fragment>
          <Container className='text-center'>
          <h1 className='servicesMainTitle'>RECENT PROJECTS</h1>
            <div className='bottom'></div>
              <Row>
              <Col lg={4} md={6} sm={12}>                  

<Card className='projectCard'>
  <Card.Img variant="top" src={page1IMG} />
  <Card.Body>
    <Card.Title className='serviceName'>E-commerce</Card.Title>
    <Card.Text className='serviceDescription'>
      Some quick example text to build on the card title and make up the bulk of
      the card's content.
    </Card.Text>
    <Button variant="primary"><Link className="link-style" to="/projectdetails"> View More </Link>  </Button>
  </Card.Body>
</Card>
              </Col>
              <Col lg={4} md={6} sm={12}>  
              <Card className='projectCard'>
  <Card.Img variant="top" src={page2IMG} />
  <Card.Body>
    <Card.Title className='serviceName'>SEO Google</Card.Title>
    <Card.Text className='serviceDescription'>
      Some quick example text to build on the card title and make up the bulk of
      the card's content.
    </Card.Text>
    <Button variant="primary"><Link className="link-style"  to="/projectdetails"> View More </Link>  </Button>
  </Card.Body>
</Card>
              </Col>
              <Col lg={4} md={6} sm={12}>  
              <Card className='projectCard'>
  <Card.Img variant="top" src={page3IMG} />
  <Card.Body>
    <Card.Title className='serviceName'>ERP Odoo</Card.Title>
    <Card.Text className='serviceDescription'>
      Some quick example text to build on the card title and make up the bulk of
      the card's content.
    </Card.Text>
    <Button variant="primary"><Link className="link-style"  to="/projectdetails"> View More </Link>  </Button>
  </Card.Body>
</Card>
              </Col>
              </Row>
          </Container>
      </Fragment>
    )
  }
}

export default RecentProject
