import React, { Component, Fragment } from 'react'
import AllCourses from '../components/AllCourses/AllCourses'
import Footer from '../components/Footer/Footer'
import TopPage from '../components/TopPage/TopPage'
import TopNavigation from '../components/TopNavigation/TopNavigation'
class AllCoursesPage extends Component {
  componentDidMount(){
    window.scroll(0,0)
}
  render() {
    return (
        <Fragment>
            <TopNavigation title="All Courses"  />  
            <TopPage pagetitle="All Courses" />              
            <AllCourses />
            <Footer />
        </Fragment>
    )
  }
}

export default AllCoursesPage
