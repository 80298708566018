import React, { Component, Fragment } from 'react'
import Footer from '../components/Footer/Footer'
import TopPage from '../components/TopPage/TopPage'
import TopNavigation from '../components/TopNavigation/TopNavigation'
import RefundDescription from '../components/RefundDescription/RefundDescription'

class RefundPage extends Component {
  componentDidMount(){
    window.scroll(0,0)
}
  render() {
    return (
        <Fragment>
            <TopNavigation title="Refund Policy" /> 
            <TopPage pagetitle="Refund Policy" />  
            <RefundDescription />
            <Footer />
        </Fragment>
    )
  }
}

export default RefundPage
