import React, { Component, Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import course1IMG from '../../asset/image/course1.jpg';
import course2IMG from '../../asset/image/course2.jpg';
import course3IMG from '../../asset/image/course3.jpg';
import { Link } from 'react-router-dom'

class Courses extends Component {
  render() {
    return (
      <Fragment>
          <Container className='text-center'>
          <h1 className='servicesMainTitle'>OWR COURSES</h1>
            <div className='bottom'></div>
              <Row>
                  <Col lg={6} md={12} sm={12}>
                    <Row>
                        <Col lg={6} md={12} sm={12} className="p-2">
                            <img className='courseImg' src={course1IMG} />
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <h5 className='textJustify serviceName'>WEB Developpment</h5>
                            <p className='textJustify serviceDescription'>Learn How to mamage project in real life. Some example text to build on the card title.</p>
                            {/* <a className='courseViewDetails' href="#">View Details</a> */}
                            <Link className="courseViewDetails float-left" to="/coursedetails" >View Details</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={12} sm={12} className="p-2">
                            <img className='courseImg' src={course2IMG} />
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <h5 className='textJustify serviceName'>Learn Maths</h5>
                            <p className='textJustify serviceDescription'>Learn How to mamage project in real life. Some example text to build on the card title.</p>
                            {/* <a className='courseViewDetails' href="#">View Details</a> */}
                            <Link className="courseViewDetails float-left" to="/coursedetails" >View Details</Link>
                        </Col>
                    </Row>
                  </Col>
                  <Col lg={6} md={12} sm={12}>
                    <Row>
                        <Col lg={6} md={12} sm={12} className="p-2">
                            <img className='courseImg' src={course3IMG} />
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <h5 className='textJustify serviceName'>Big Data</h5>
                            <p className='textJustify serviceDescription'>Learn How to develop a full stack web applications. Some example text to build on the card title.</p>
                            {/* <a className='courseViewDetails' href="#">View Details</a> */}
                            <Link className="courseViewDetails float-left" to="/coursedetails" >View Details</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={12} sm={12} className="p-2">
                            <img className='courseImg' src="https://img.freepik.com/photos-gratuite/achats-ligne-paiement-e-commerce-banque_53876-127604.jpg?t=st=1652397895~exp=1652398495~hmac=035992ca1053ab55cd86245e92b87ef2a808979315338b03c3d947aee49a6a7d&w=740" />
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                            <h5 className='textJustify serviceName'>Learn Ecommerce</h5>
                            <p className='textJustify serviceDescription'>Learn How to develop a full stack web applications. Some example text to build on the card title.</p>
                            {/* <a className='courseViewDetails' href="#">View Details</a> */}
                            <Link className="courseViewDetails float-left" to="/coursedetails" >View Details</Link>
                        </Col>
                    </Row>
                  </Col>
              </Row>
          </Container>
      </Fragment>
    )
  }
}

export default Courses
