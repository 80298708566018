import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Modal, Button } from 'react-bootstrap'
import faceIMG from '../../asset/image/face.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick'

class ClientReview extends Component {
    
  render() {

    var settings = {
        autoPlaySpeed:3000,
        autoPlay:true,
        dots: false,
        infinite: true,
        speed: 3000,
        arrows:false,
        vertical:true,
        verticalSwiping:true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    return (
        <Fragment>
            <Container className='text-center'>
    <h1 className='servicesMainTitle'>REVIEWS</h1>
            <div className='bottom'></div>
            </Container>
            
        <Container fluid={true} className='siderBack'>            
            <Slider {...settings}>
            <div>
                <Row className="text-center justify-content-center">
                    <Col lg={6} md={6} sm={12} className="p-3">

                    <img className='reviewImg' src={faceIMG} />                
                    <h2 className="reviewName"><FontAwesomeIcon icon={faStar} className="iconStartReview" /> Kazi Ariyan <FontAwesomeIcon icon={faStar} className="iconStartReview" /></h2>
                    <p className="reviewDescription">Hi! I'm Kazi Ariyan. I'm a web developer with a serious love for teaching I am a founder of eLe easy Learning and a passionate Web Developer, Programmer & Instructor.</p>

                    </Col>
                </Row> 
            </div>
            <div>
                <Row className="text-center justify-content-center">
                    <Col lg={6} md={6} sm={12} className="p-3">

                    <img className='reviewImg' src="https://img.freepik.com/photos-gratuite/bel-homme-souriant-confiant-mains-croisees-poitrine_176420-18743.jpg?t=st=1652824270~exp=1652824870~hmac=0ee11e43c184bca108675309d0401416d6ff19826ae69ab50104a87651a1a411&w=740" />                
                    <h2 className="reviewName"><FontAwesomeIcon icon={faStar} className="iconStartReview" /> Kazi Ariyan <FontAwesomeIcon icon={faStar} className="iconStartReview" /></h2>
                    <p className="reviewDescription">Hi! I'm Kazi Ariyan. I'm a web developer with a serious love for teaching I am a founder of eLe easy Learning and a passionate Web Developer, Programmer & Instructor.</p>

                    </Col>
                </Row> 
            </div>
            </Slider>
        </Container>
        </Fragment>            
    )
  }
}

export default ClientReview
